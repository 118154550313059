import React from "react"
import Layout from "../components/layout";
import Title from '../components/common/Title.jsx';
import Animation from "../components/common/Animation.jsx"

export default () => (
    <Layout>
        <Title title="About Us" />
        <h2>coming soon...</h2>
        <Animation animation={require("../assets/coming-soon.json")} />
    </Layout>
);